import $ from 'jquery';
import * as d3 from 'd3';
import Flickity from 'flickity-imagesloaded';
import ScrollMagic from 'scrollmagic';
require('scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators');
import _ from 'lodash';

export default function() {

  d3.json('data/content.json', function(error, data) {
    if (error) throw error;

    let selection = d3.select('#app');

    buttonsEvent(selection);
    timelineInteraction(selection);
    teamCarousel(selection);

    spyTheScroll();

  });
}

function buttonsEvent(selection) {
  var btnOpen = selection.selectAll('[data-accordion="button-open"]');
  var btnClose = selection.selectAll('[data-accordion="button-close"]');

  btnOpen.on('click', function() {
    var index = d3.select(this).node().dataset.accordionIndex;

    d3.select(this)
      .classed('is-visible', false);

    var buttonsClose = d3.selectAll('[data-accordion="button-close"]');

    var buttonClose = _.filter(buttonsClose.nodes(), o => +o.dataset.accordionIndex === +index);

    d3.select(buttonClose[0])
      .classed('is-visible', true);

    $(`[data-accordion="content-${index}"]`).slideDown();

  });

  btnClose.on('click', function() {
    var index = d3.select(this).node().dataset.accordionIndex;

    d3.select(this)
      .classed('is-visible', false);

    var buttonsOpen = d3.selectAll('[data-accordion="button-open"]');

    var buttonOpen = _.filter(buttonsOpen.nodes(), o => +o.dataset.accordionIndex === +index);

    d3.select(buttonOpen[0])
      .classed('is-visible', true);

    $(`[data-accordion="content-${index}"]`).slideUp();
  });
}

function timelineInteraction(selection) {
  var button = selection.selectAll('[data-timeline="button"]');

  button.on('click', function(){
    var index = d3.select(this).node().dataset.timelineButtonIndex;

    button
      .classed('selected', false)
      .classed('animato', true);

    d3.select(this)
      .classed('selected', true)
      .classed('animato', false);

    selection
      .selectAll('[data-timeline="img"]')
      .style('display','none');

    selection
      .select(`[data-timeline-index="${index}"]`)
      .style('display', 'block');
  });
}

function teamCarousel(selection) {
  var teamCarousel = new Flickity( '.team-carousel', {
    cellAlign: 'left',
    contain: true,
    adaptiveHeight: true,
    imagesLoaded: true
  });

  let buttons = selection.selectAll('.team-nav li');

  buttons.on('click', function(){
    let index = d3.select(this).node().dataset.index;

    teamCarousel.select(index);
  });

  teamCarousel.on( 'select', function() {
   
    let index = teamCarousel.selectedIndex;

    buttons
      .classed('selected', false)
      .classed('animato', true);

    selection 
      .select('.team-nav')
      .select(`[data-index="${index}"]`)
      .classed('selected', true)
      .classed('animato', false);
    
  });
}

function spyTheScroll() {
  let controller = new ScrollMagic.Controller();
  let sections = d3.selectAll('[data-action="spy"]');
  let currentSection;

  sections.each(function() {
    let elId = this.id;

    addSpyScenes(controller, elId);
    addScrollTo(elId);
  });

  addPinScene(controller, 'timeline-pin', 'spy-timeline');

  function addSpyScenes(controller, triggerId) {

    var scene = new ScrollMagic.Scene({
      triggerElement: `#${triggerId}`,
      triggerHook: 0,
      offset: 0
    }).on('enter', function(e) {
      d3.selectAll('#spy-timeline [data-section]').each(function() {
        d3.select(this).classed('is-active', false);
      });

      d3.select(`#spy-timeline [data-section="${e.target.triggerElement().id}"]`).classed('is-active', true);

      currentSection = e.target.triggerElement().id;

      // if supported by the browser we can even update the URL.
      if (window.history && window.history.pushState) {
        history.pushState('', document.title, `#${triggerId}`);
      }
    }).on('leave', function(e) {
      d3.selectAll('#spy-timeline [data-section]').each(function() {
        d3.select(this).classed('is-active', false);
      });

      d3.select(`#spy-timeline [data-section="${e.target.triggerElement().id}"]`).classed('is-active', true);

      // if supported by the browser we can even update the URL.
      if (window.history && window.history.pushState) {
        history.pushState('', document.title, `#${triggerId}`);
      }
    });
    // .addIndicators();

    // Add Scene to ScrollMagic Controller
    controller.addScene(scene);
  }

  function addPinScene(controller, triggerId, pinId) {
    new ScrollMagic.Scene({
      triggerElement: `#${triggerId}`,
      triggerHook: 0,
      duration: d3.select(`#${triggerId}`).node().getBoundingClientRect().height,
      reverse: true
    })
    .setPin(`#${pinId}`)
    .addTo(controller);
  }

  let positionsHistory = [];

  function addScrollTo(triggerId) {


    d3.select(`#spy-timeline [data-section="${triggerId}"]`)
      .on('click', function() {

        let currentPosition = $(`#${triggerId}`).offset().top;
        let offset = 1;

        $('html, body').animate({
          scrollTop: currentPosition
        }, 800);

        positionsHistory.push(currentPosition);
        let historyLenght = positionsHistory.length;
        let penultimatePosition = positionsHistory[historyLenght - 2] ? positionsHistory[historyLenght - 2] : $(`#${currentSection}`).offset().top;

        if (currentPosition > penultimatePosition) {
          offset = 1;
        } else {
          offset = -1;
        }

        $('html, body').animate({
          scrollTop: currentPosition + offset
        }, 0);

        // if supported by the browser we can even update the URL.
        if (window.history && window.history.pushState) {
          history.pushState('', document.title, `#${triggerId}`);
        }
      });
  }
}
